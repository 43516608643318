import React from "react";
import styled from "styled-components";
import { theme } from "../../theme/theme.js";

const Heading = styled.h4`
  h4 {
    font-family: Sofia;
    font-style: normal;
    font-weight: ${(props) => (props.lighter ? 100 : "normal")};
    text-align: ${(props) => (props.signature ? "center" : null)};
  }
  font-size: 1.25rem;
  line-height: 140%;
  color: ${theme.black};
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

function H4({ children, handleClick, lighter, signature, style={} }) {
  return (
    <Heading lighter={lighter} signature={signature}>
      <h4 onClick={handleClick ? handleClick : null} style={style}>{children}</h4>
    </Heading>
  );
}

export default H4;
