import { TOGGLE_FAVORITES } from "../actions/favorites";

const initState = {
  favorites: { circuits: [], pois: [], events: [], experiences: [] },
};

export default function tagsReducer(state = initState, action) {
  switch (action.type) {
    case TOGGLE_FAVORITES:
      const favorite = action.payload;
      const favorites = state.favorites;
      if (favorite.pois) {
        const isFavoriteExistpoi =
          state.favorites.pois.indexOf(favorite.pois[0]) > -1;
        if (isFavoriteExistpoi) {
          const filtredFavorites = favorites.pois.filter(
            (f) => !favorite.pois.includes(f)
          );

          return {
            ...state,
            favorites: {
              ...state.favorites,
              pois: [...filtredFavorites],
            },
          };
        }
        return {
          favorites: {
            ...state.favorites,
            pois: [...state.favorites.pois, ...favorite.pois],
          },
        };
      }
      if (favorite.experiences) {
        const isFavoriteExistexp =
          state.favorites.experiences.indexOf(favorite.experiences[0]) > -1;

        if (isFavoriteExistexp) {
          const filtredFavorites = favorites.experiences.filter(
            (f) => !favorite.experiences.includes(f)
          );

          return {
            ...state,
            favorites: {
              ...state.favorites,
              experiences: [...filtredFavorites],
            },
          };
        }
        return {
          favorites: {
            ...state.favorites,
            experiences: [
              ...state.favorites.experiences,
              ...favorite.experiences,
            ],
          },
        };
      }
      if (favorite.circuits) {
        const isFavoriteExistcircuit =
          state.favorites.circuits.indexOf(favorite.circuits[0]) > -1;
        if (isFavoriteExistcircuit) {
          const filtredFavorites = favorites.circuits.filter(
            (f) => !favorite.circuits.includes(f)
          );

          return {
            ...state,
            favorites: {
              ...state.favorites,
              circuits: [...filtredFavorites],
            },
          };
        }
        return {
          favorites: {
            ...state.favorites,
            circuits: [...state.favorites.circuits, ...favorite.circuits],
          },
        };
      }
      if (favorite.events) {
        const isFavoriteExistevent =
          state.favorites.events.indexOf(favorite.events[0]) > -1;
        if (isFavoriteExistevent) {
          const filtredFavorites = favorites.events.filter(
            (f) => !favorite.events.includes(f)
          );

          return {
            ...state,
            favorites: {
              ...state.favorites,
              events: [...filtredFavorites],
            },
          };
        }
        return {
          favorites: {
            ...state.favorites,
            events: [...state.favorites.events, ...favorite.events],
          },
        };
      }

      return {
        favorites: { ...state.favorites, ...action.payload },
      };

    default:
      return state;
  }
}
