import {
  SET_TOUR,
  SET_STARTING_POINT,
  TOUR_ADD_PLACE,
  TOUR_REMOVE_PLACE,
  SAVE_TOUR,
} from "../actions/tour";

import { DEFAULT_MAP_CENTER } from '../constants'


const initState = {
  tour: [],
  startingPoint: DEFAULT_MAP_CENTER,
  archive: [],
};

export default function tourReducer(state = initState, action) {
  switch (action.type) {
    case SET_TOUR:
      return {
        ...state,
        tour: [...action.payload],
        archive: state.archive.length > 0 ? state.archive : [...action.payload],
      };

    case SAVE_TOUR:
      return {
        ...state,
        archive: action.payload,
      };

    case SET_STARTING_POINT:
      return {
        ...state,
        startingPoint: [...action.payload],
      };

    case TOUR_ADD_PLACE:
      const { day, place, step } = action.payload;
      const newTourState = state.tour.map((t) => {
        if (t.day === day) {
          const places = [...t.places]
          places.splice(step, 0, place);
          t.places = [...places]
        }
        return t;
      });

      const newArchiveState = state.archive.map((t) => {
        if (t.day === day) {
          t.places.push(place);
        }
        return t;
      });

      return {
        ...state,
        tour: newTourState,
        archive: newArchiveState,
      };
    case TOUR_REMOVE_PLACE:
      const { id } = action.payload;
      const fTour = state.tour.map((t) => {
        if (t.day === action.payload.day) {
          return { ...t, places: t.places.filter((k) => k._id !== id) };
        } else {
          return t;
        }
      });

      const fTourArchive = state.archive.map((t) => {
        if (t.day === action.payload.day) {
          return { ...t, places: t.places.filter((k) => k._id !== id) };
        } else {
          return t;
        }
      });

      /*const filterByDateAndTourId = state.tour.map(t => {
                const places = t.places.map(p => {
                    if (p._id !== id) {
                        return p
                    }
                })
                t.places = [...places]
                return t
                
            })*/

      return {
        ...state,
        tour: fTour,
        archive: fTourArchive,
      };
    default:
      return state;
  }
}
