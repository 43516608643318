import { SET_STORIES } from '../actions/stories'

const initState = {
    stories: []
}
  
export default function storiesReducer (state = initState, action) {
    switch (action.type) {
      case SET_STORIES:
            return {
              stories: action.payload.sort((a,b)=>a.order-b.order)
        }
      default:
        return state
    }
  }
  
