import { API_TAGS, JSON_HEADERS } from '../constants'
import { message } from '../helpers';

export const SET_TAGS = 'SET_TAGS'

// setAuthToken
function setTags(tags) {
    return {
        type: SET_TAGS,
        payload: tags
    }
}

// get tags from the server
export function fetchSetTags() {
    return function (dispatch) {
        return fetchApiGetTags()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        const sortedTags = response.data
                            .sort((a, b) => {
                                if (a.slug < b.slug) {
                                    return -1;
                                }
                                if (a.slug > b.slug) {
                                    return 1;
                                }
                                return 0;
                            })
                        dispatch(setTags(sortedTags))
                    } else {
                        message.error('Something bad happens. Code: TE01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: TE02'))
    }
    
}

function fetchApiGetTags() {
    return fetch(API_TAGS ,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}