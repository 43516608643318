import Places from './places'
import Quotes from './quotes'
import Tags from './tags'
import Articles from './articles'
import Filters from './filters'
import Favorites from './favorites'
import Cities from './cities'
import Experiences from './experiences'
import Tour from './tour'
import Mice from './mice'
import Local from './local'
import User from './user'
import Stories from './stories'
import Circuits from './circuits'
import Vote from './vote'
import InstaWall from './instawall'
//import Stats from './stats'

const reducers =  {
    Places,
    Tags,
    Filters,
    Favorites,
    Cities,
    Experiences,
    Tour,
    Quotes,
    Mice,
    Articles,
    Local,
    User,
    Stories,
    Circuits,
    Vote,
    InstaWall
    //Stats
}

export default reducers