import { Redirect, BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import Footer from "../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import Nav from "../components/Nav/Nav";
import UpperNav from "../components/Nav/UpperNav";
import Loading from "../components/Loading/Loading";
import Spacer from "../components/Spacer/Spacer";
import MobileNav from "../components/MobileNav/MobileNav";
import ScrollTop from "../pages/ScrollTop";
import TrackPageView from "../pages/TrackPageView";
import TripPdf from "../pages/TripPdf";
import FetchData from "./FetchData";
import AppTitle from "../pages/AppTitle";
import isMobile from "ismobilejs";
const Home = lazy(() => import("../pages/Home"));
const TagsSearch = lazy(() => import("../pages/TagsSearch"));
const POI = lazy(() => import("../pages/POI"));
const Search = lazy(() => import("../pages/Search"));
const Deals = lazy(() => import("../pages/Deals"));
const Favorites = lazy(() => import("../pages/Favorites"));
const Contactp = lazy(() => import("../pages/Contactp"));
const Preference = lazy(() => import("../pages/Preference"));
const Destination = lazy(() => import("../pages/Destination"));
const Event = lazy(() => import("../pages/Event"));
const Circuit = lazy(() => import("../pages/Circuit"));
const Experience = lazy(() => import("../pages/Experience"));
const About = lazy(() => import("../pages/About"));
const TripPlanner = lazy(() => import("../pages/TripPlanner"));
const Deal = lazy(() => import("../pages/Deal"));
const Visiting = lazy(() => import("../pages/Visiting"));
const Inspire = lazy(() => import("../pages/Inspire"));
const Whoarewe = lazy(() => import("../pages/Whoarewe"));
const Doc = lazy(() => import("../pages/Doc"));

export default function App() {
  const { filters } = useSelector((state) => state.Filters);
  const [contact, setContact] = React.useState(false);
  const { t } = useTranslation();
  React.useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 500);
  }, []);
  return (
    <Router>
      <div style={{ position: "relative" }}>
        {!isMobile().phone && (
          <>
            <UpperNav />

            <Nav contact={contact} setContact={setContact} />
          </>
        )}
        {isMobile().phone && <MobileNav />}

        <Spacer bottom="11rem" />

        <AnimatedSwitch className="switch-wrapper">
          <Route exact path="/">
            <Suspense fallback={<Loading />}>
              <Redirect to={`/en`} />
            </Suspense>
          </Route>
          <Route key="POI" exact path="/:locale/poi/:id">
            <Suspense fallback={<Loading />}>
              <POI />
            </Suspense>
          </Route>
          <Route key="Deal" exact path="/:locale/deal/:id">
            <Suspense fallback={<Loading />}>
              <Deal />
            </Suspense>
          </Route>
          <Route key="Home" exact path="/:locale?">
            {filters.stade === "planning" ? (
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            ) : filters.stade === "inspire" ? (
              <Suspense fallback={<Loading />}>
                <Inspire />
              </Suspense>
            ) : (
              <Suspense fallback={<Loading />}>
                <Visiting />
              </Suspense>
            )}
          </Route>
          <Route key="Search" exact path="/:locale/search">
            <Suspense fallback={<Loading />}>
              <Search />
            </Suspense>
          </Route>
          <Route key="Deals" exact path="/:locale/deals">
            <Suspense fallback={<Loading />}>
              <Deals />
            </Suspense>
          </Route>
          <Route key="Favorites" exact path="/:locale/fav">
            <Suspense fallback={<Loading />}>
              <Favorites />
            </Suspense>
          </Route>
          <Route key="Contact" exact path="/:locale/contact">
            <Suspense fallback={<Loading />}>
              <Contactp />
            </Suspense>
          </Route>
          <Route key="Destination" exact path="/:locale/destination/:id">
            <Suspense fallback={<Loading />}>
              <Destination />
            </Suspense>
          </Route>
          <Route key="Preference" exact path="/:locale/preference/:id">
            <Suspense fallback={<Loading />}>
              <Preference />
            </Suspense>
          </Route>
          <Route key="Event" exact path="/:locale/event/:id">
            <Suspense fallback={<Loading />}>
              <Event />
            </Suspense>
          </Route>
          <Route key="Circuit" exact path="/:locale/circuit/:id">
            <Suspense fallback={<Loading />}>
              <Circuit />
            </Suspense>
          </Route>
          <Route key="Experience" exact path="/:locale/experience/:id">
            <Suspense fallback={<Loading />}>
              <Experience />
            </Suspense>
          </Route>
          <Route key="About" exact path="/:locale/about">
            <Suspense fallback={<Loading />}>
              <About />
            </Suspense>
          </Route>
          <Route key="lookfor" exact path="/:locale/lookfor/:id">
            <Suspense fallback={<Loading />}>
              <TagsSearch />
            </Suspense>
          </Route>
          <Route key="lookfor" exact path="/:locale/whoarewe">
            <Suspense fallback={<Loading />}>
              <Whoarewe />
            </Suspense>
          </Route>
          <Route key="mapsguides" exact path="/:locale/mapsguides">
            <Suspense fallback={<Loading />}>
              <Doc />
            </Suspense>
          </Route>
          <Route
            key="pdf"
            exact
            path="/:locale/pdf"
            render={(props) => (
              <Suspense fallback={<Loading />}>
                <TripPdf {...props} />
              </Suspense>
            )}
          />
          <Route
            key="Plan"
            exact
            path="/:locale/plan/:id?"
            render={(props) => (
              <Suspense fallback={<Loading />}>
                <TripPlanner route={props} />
              </Suspense>
            )}
          ></Route>
        </AnimatedSwitch>
        <Spacer bottom="124px" />
        <Footer />
        <FetchData />
        <ScrollTop />
        <TrackPageView />
        <AppTitle title={t("AppName")} />
      </div>
    </Router>
  );
}
