import { API_STORIES, JSON_HEADERS } from '../constants'
import { message } from '../helpers';

export const SET_STORIES = 'SET_STORIES'

// setAuthToken
function setStories(stories) {
    return {
        type: SET_STORIES,
        payload: stories
    }
}

// get stories from the server
export function fetchSetStories() {
    return function (dispatch) {
        return fetchApiGetStories()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setStories(response.data))
                    } else {
                        message.error('Something bad happens. Code: STORYE01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: STORYTE02'))
    }
    
}

function fetchApiGetStories() {
    return fetch(API_STORIES ,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}