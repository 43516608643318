import React from "react";
import styled from "styled-components";
import FlexRow from "../FlexRow/FlexRow";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import tadariss from "../../images/tadariss.svg";
import { theme } from "../../theme/theme";
import { getSafe } from "../../helpers";
import { useTranslation } from "react-i18next";
import { EXACT_MAIN_MENU_TAGS } from "../../constants";
import isMobile from "ismobilejs";
const Wrapper = styled.div`
  height: ${(props) => (props.open ? "100vh" : "0vh")};
  width: ${(props) => (props.open ? "100vw" : "0vw")};
  background: ${theme.green};
  position: fixed;
  transition: all 0.4s;
  border-bottom-right-radius: ${(props) => (props.open ? "0px" : "1000px")};
  top: 0;
  left: 0;
  @media (max-width: 768px) {
    height: 99vh;
  }
  z-index: ${(props) => (props.open ? 999999999 : -1)};

  > * {
    display: ${(props) => (props.open ? "flex" : "none")};
    opacity: ${(props) => (props.open ? "1" : "0")};
  }
  .flexx {
    display: -webkit-flex;
    flex-direction: row;
    height: 100vh;
    align-items: flex-start !important;
    text-decoration: none !important;
    padding-top: 10%;
    @media (max-width: 768px) {
      flex-direction: column;
      height: 50vh;
      gap: 60px;
    }
    &:hover {
      text-decoration: none !important;
    }
  }
  .row {
    display: -webkit-flex;
    flex-direction: row;
    align-items: flex-start;
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
    .col {
      display: -webkit-flex;
      flex-direction: column;
      gap: 32px;
      justify-content: center;
      &:hover {
        text-decoration: none !important;
      }
    }
    &__title {
      font-family: Sofia;
      font-style: normal;
      font-weight: normal;
      height: fit-content;
      transform: rotate(-90deg);
      margin-top: 2.8125rem;
      font-size: 1.25rem;
      color: #ffac46 !important;
      @media (max-width: 768px) {
        font-size: 14px;
        margin-top: 29px;
      }
    }
    h3 {
      font-family: Ivy;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 120%;
      letter-spacing: 0.06em;
      @media (max-width: 768px) {
        font-size: 16px;
      }
      color: #ffffff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .rightside {
    position: relative;
    object-fit: cover;
    width: 40%;
    height: 100vh;
    .close {
      position: absolute;
      right: 51px;
      top: 51px;
      width: 106px;
      height: 106px;
      background: ${theme.green};
      cursor: pointer;
      border-radius: 1000px;
      z-index: 9999;
      [class^="line"] {
        width: 19px;
        height: 2px;
        background: ${theme.white};
        position: absolute;
        left: 50%;
        top: 50%;
      }
      .line1 {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      .line2 {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 60;
      object-fit: cover;
    }
  }
`;

function Menu({ open, setOpen, local }) {
  const { tags } = useSelector((state) => state.Tags);
  const { cities } = useSelector((state) => state.Cities);
  const [filtered, setFiltered] = React.useState([]);
  let { t } = useTranslation();
  const history = useHistory();
  function importAll(r) {
    let images = {};
    // eslint-disable-next-line array-callback-return
    r.keys().map((item, index) => {
      images[item.replace("../", "")] = r(item);
    });
    return images;
  }

  const images = importAll(
    require.context("../../images/Photos Menu", false, /\.(png|jpe?g|svg)$/)
  );
  const keys = Object.keys(images);
  React.useEffect(() => {
    let output = tags.filter((p) => EXACT_MAIN_MENU_TAGS.indexOf(p.slug) > -1);
    setFiltered(output);
  }, [tags]);
  return (
    <Wrapper open={open}>
      <FlexRow>
        <div className="flexx">
          {!isMobile().phone && <img src={tadariss} alt="Tadariss" />}
          <div className="row">
            <h2 className="row__title">{t("destination")}</h2>
            <div className="col">
              {cities.map(
                (city, i) =>
                  city.slug !== "volubilis" && (
                    <h3
                      key={i}
                      onClick={() => {
                        history.push(`/${local}/destination/${city.id}`);
                        setOpen(false);
                      }}
                    >
                      {city.title[local]}
                    </h3>
                  )
              )}
            </div>
          </div>
          <div className="row">
            <h2 className="row__title">{t("navSeeDo")}</h2>
            <div className="col">
              {filtered.map((tag, i) => (
                <h3
                  key={i}
                  onClick={() => {
                    history.push(`/${local}/preference/${tag.id}`);
                    setOpen(false);
                  }}
                >
                  {tag.name[local]}
                </h3>
              ))}
            </div>
          </div>
        </div>
        {!isMobile().phone && (
          <div className="rightside">
            <div className="close" onClick={() => setOpen(false)}>
              <div className="line1"></div>
              <div className="line2"></div>
            </div>
            <img
              src={getSafe(
                () => images[keys[Math.floor(Math.random() * 8)]].default
              )}
              alt="img"
            />
          </div>
        )}
      </FlexRow>
    </Wrapper>
  );
}

export default Menu;
