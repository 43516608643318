import { getSafe } from "../helpers";
import Pdf from "react-to-pdf";
import React from "react";
// Create styles
const styles = {
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  section: {
    margin: 10,
    padding: 10,
    display: "block",
  },
};

// Create Document Component
const MyDocument = ({ location }) => {
  const ref = React.useRef(null);
  const button = React.useRef(null);
  const { query } = location;
  const { tour, local } = query ? query : {};
  React.useEffect(() => {
    button.current.click();
  }, [button]);
  return (
    <div>
      <Pdf targetRef={ref} filename="tour.pdf">
        {({ toPdf }) => (
          <button ref={button} style={{ opacity: 0 }} onClick={toPdf}>
            Generate Pdf
          </button>
        )}
      </Pdf>
      <div ref={ref}>
        {tour &&
          tour.map((t, k) => (
            <div style={styles.section} k={k + "asjj"}>
              <p>
                Day {k + 1} - {t.city}
              </p>
              <br />
              <div style={styles.section}>
                {t.places.map((p, l) => (
                  <div key={l + "klals"} style={{ marginBottom: "20px" }}>
                    <h1 key={l + "ajk4"}>{p.title[local]} </h1>
                    <p>{getSafe(() => p.contact.address.street_address)}</p>
                    <p>{getSafe(() => p.contact.phone)}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MyDocument;
