import React from "react";
import styled from "styled-components";
import { theme } from "../../theme/theme.js";

const Heading = styled.div`
  font-family: Ivy;
  font-style: normal;
  font-weight: 100;
  font-size: ${(props) =>
    props.big
      ? "5rem"
      : props.alotbig
      ? "9rem"
      : props.small
      ? "1.71rem"
      : "3.42rem"};
  line-height: 130%;
  font-weight: ${(props) => (props.bold ? 400 : props.boldish ? 300 : null)};
  width: ${(props) => (props.notwide ? "45%" : null)};
  text-align: ${(props) => (props.bold ? "center" : null)};
  color: ${(props) =>
    props.active ? theme.green : props.opaci ? "rgba(0,0,0,0.3)" : theme.black};
  border-bottom: ${(props) =>
    props.active ? `2px solid ${theme.green}` : null};
  padding: ${(props) => (props.active ? "40px 0px" : null)};
  span {
    font-weight: ${(props) => (props.bold ? 800 : null)};
    font-style: ${(props) => (props.bold ? "italic" : null)};
  }
  @media (max-width: 768px) {
    font-weight: ${(props) => (props.bold ? 200 : null)};
    width: ${(props) => (props.notwide ? "80%" : null)};
    text-align: ${(props) => (props.bold ? "center" : null)};
    font-size: ${(props) => (props.big ? "20px" : "22px")};
  }
`;

function H1({
  children,
  big,
  bold,
  notwide,
  alotbig,
  small,
  active,
  boldish,
  onClick,
  opaci,
}) {
  return (
    <Heading
      onClick={() => (onClick ? onClick() : null)}
      opaci={opaci}
      boldish={boldish}
      active={active}
      small={small}
      alotbig={alotbig}
      big={big}
      bold={bold}
      notwide={notwide}
    >
      {children}
    </Heading>
  );
}

export default H1;
