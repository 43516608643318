import { API_CIRCUITS, JSON_HEADERS } from '../constants'
import { message } from '../helpers';

export const SET_CIRCUITS = 'SET_CIRCUITS'

// setAuthToken
function setCitites(circuits) {
    return {
        type: SET_CIRCUITS,
        payload: circuits
    }
}

// get circuits from the server
export function fetchSetCircuits() {
    return function (dispatch) {
        return fetchApiGetCircuits()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setCitites(response.data))
                    } else {
                        message.error('Something bad happens. Code: CIRCT01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: CIRCT02'))
    }
    
}

function fetchApiGetCircuits() {
    return fetch(API_CIRCUITS ,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}