import React from "react";
import styled from "styled-components";
import { theme } from "../../theme/theme";
const Wrapper = styled.div`
  position: relative;
  z-index: 999999999 !important;
  background: transparent !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  display: -webkit-flex;
  justify-content: center;
  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    &:not(:first-child) {
      margin-left: 10px;
    }

    background-color: #4b9cdb;
    &:nth-last-child(1) {
      background-color: ${theme.green};
    }
    &:nth-last-child(2) {
      background-color: ${theme.yellowish};
    }
    &:nth-last-child(3) {
      background-color: ${theme.lightblue};
    }
    &:nth-last-child(4) {
      background-color: ${theme.orange};
    }
  }
  .load-3 .line:nth-last-child(1) {
    animation: loadingC 0.6s 0.1s linear infinite;
  }
  .load-3 .line:nth-last-child(2) {
    animation: loadingC 0.6s 0.2s linear infinite;
  }
  .load-3 .line:nth-last-child(3) {
    animation: loadingC 0.6s 0.3s linear infinite;
  }
  .load-3 .line:nth-last-child(4) {
    animation: loadingC 0.6s 0.4s linear infinite;
  }
  @keyframes loadingC {
    0 {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;

function Loading() {
  return (
    <Wrapper>
      <div class="load-wrapp">
        <div class="load-3">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Loading;
