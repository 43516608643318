import React from "react";
import styled from "styled-components";
import { theme } from "../../theme/theme.js";

const Heading = styled.div`
  font-family: Sofia;
  font-style: normal;
  font-weight: ${(props) => (props.light ? 100 : "normal")};
  font-size: 1.25rem;
  line-height: 130%;
  width: 100%;
  color: ${theme.black};
  text-align: ${(props) => (props.squeeze ? "center" : null)};
  width: ${(props) => (props.squeeze ? "40%" : null)};
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

function P({ children, squeeze, light, onClick }) {
  return (
    <Heading onClick={onClick ? onClick : null} squeeze={squeeze} light={light}>
      {children}
    </Heading>
  );
}

export default P;
