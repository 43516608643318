import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import configureStore from "./store";
import App from "./layout/App";
import "./i18n";
import "react-lazy-load-image-component/src/effects/blur.css";
import "mapbox-gl/dist/mapbox-gl.css";

const { store, persistor } = configureStore();

const instance = createInstance({
  urlBase: "https://analytics.ctd.ma",
  siteId: 3,
});

const Main = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MatomoProvider value={instance}>
        <Auth0Provider
          domain="dev-74kxjb69.eu.auth0.com"
          clientId="q3EaMC9UXgYkU3QfIISfY3oI4WokHkvK"
          redirectUri={window.location.origin}
        >
          <App />
        </Auth0Provider>
      </MatomoProvider>
    </PersistGate>
  </Provider>
);

export default Main;
