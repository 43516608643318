import { SET_CIRCUITS } from '../actions/circuits'

const initState = {
    circuits: []
}
  
export default function citiesReducer (state = initState, action) {
    switch (action.type) {
      case SET_CIRCUITS:
            return {
              circuits: action.payload
        }
      default:
        return state
    }
  }
  
