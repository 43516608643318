import React from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setFilters } from "../../actions/filters";
import happy from "../../images/compass.svg";
import bag from "../../images/bag-2.svg";
import marker from "../../images/marker.svg";

import Menu from "../Menu/Menu";
import MenuP from "../Menu/MenuPlan";
import H1 from "../H1/H1";
import heart from "../../icons/Heart.svg";
import search from "../../icons/search-outline.svg";
import logo from "../../images/logo.svg";

const Wrapper = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 7.625rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 114px;
  @media (min-width: 800px) and (max-width: 1400px) {
    height: 100px;
  }
  @media (min-width: 1401px) and (max-width: 1800px) {
    height: 111px;
  }
  padding-bottom: -23.5px;

  .img {
    z-index: 99999;
    position: absolute;
    background: white;
    cursor: pointer;
    width: 140px;
    height: 140px;
    left: 50%;
    transform: translateX(-50%);
    top: 80px;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .flex-start,
  .flex-end {
    > *:hover {
      text-decoration: underline !important;
    }
  }
  .eleme {
    display: -webkit-flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
  [class^="flex"] {
    display: -webkit-flex;
    object-fit: cover;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    > *:hover {
      cursor: pointer;
    }
  }
  .socials {
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    img {
      &:first-child {
        width: 21px;
        height: 24px;
      }
      width: 24px;
      height: 24px;
    }
  }
`;

function Nav({ contact, setContact }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { local } = useSelector((state) => state.Local);
  const { filters } = useSelector((state) => state.Filters);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  React.useEffect(() => {
    pathname === `/${local}/contact` ? setContact(true) : setContact(false);
    console.log(pathname);
  }, [pathname, setContact, local]);
  return (
    <Wrapper>
      <div className="flex-start">
        <div className="eleme">
          <img src={happy} alt="" />
          <H1 small boldish onClick={() => setOpen(true)}>
            {t("navSeeDo")}
          </H1>
        </div>
        <div className="eleme">
          <img src={marker} alt="" />
          <H1 small boldish onClick={() => setOpen2(true)}>
            {t("navPlanStay")}
          </H1>
        </div>
        <div className="eleme">
          <img src={bag} alt="" />
          <H1 small boldish onClick={() =>  window.open(`https://reservation.visitfesmeknes.ma`)}>
            {t("navShopOffer")}
          </H1>
        </div>
      </div>
      <Menu open={open} setOpen={setOpen} local={local} />
      <MenuP open={open2} setOpen={setOpen2} local={local} />
      
      <div className="img" style={contact ? {background:'transparent'} : {}}>
          <img
            onClick={() => history.push(`/${local}?`)}
            src={logo}
            alt="logo"
          />
        </div>
      
      <div className="flex-end">
        {filters.stade === "inspire" ? (
          <H1
            small
            active
            handleClick={() => {
              dispatch(setFilters({ stade: "inspire" }));
              history.push(`/${local}`);
            }}
          >
            {t("inspire")}
          </H1>
        ) : (
          <H1
            small
            opaci
            boldish
            onClick={() => {
              dispatch(setFilters({ stade: "inspire" }));
              history.push(`/${local}`);
            }}
          >
            {t("inspire")}
          </H1>
        )}
        {filters.stade === "planning" ? (
          <H1
            boldish
            small
            active
            handleClick={() => {
              dispatch(setFilters({ stade: "planning" }));
              history.push(`/${local}`);
            }}
          >
            {t("helpme")}
          </H1>
        ) : (
          <H1
            small
            boldish
            opaci
            onClick={() => {
              dispatch(setFilters({ stade: "planning" }));
              history.push(`/${local}`);
            }}
          >
            {t("helpme")}
          </H1>
        )}
        {filters.stade === "visiting" ? (
          <H1
            small
            active
            boldish
            handleClick={() => {
              dispatch(setFilters({ stade: "visiting" }));
              history.push(`/${local}`);
            }}
          >
            {t("imvisiting")}
          </H1>
        ) : (
          <H1
            small
            opaci
            boldish
            onClick={() => {
              dispatch(setFilters({ stade: "visiting" }));
              history.push(`/${local}`);
            }}
          >
            {t("imvisiting")}
          </H1>
        )}
        <div className="socials">
          <img
            onClick={() => history.push(`/${local}/search`)}
            src={search}
            alt="search"
          />
          <img
            onClick={() => history.push(`/${local}/fav`)}
            src={heart}
            alt="favs"
          />
        </div>
      </div>
    </Wrapper>
  );
}

export default Nav;
