import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

function TrackPageView({ history }) {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    const unlisten = history.listen(() => {
      trackPageView();
    });
    return () => {
      unlisten();
    };
  }, [history, trackPageView]);

  return null;
}

export default withRouter(TrackPageView);
