/* eslint-disable array-callback-return */
import React from "react";
import styled from "styled-components";
import FlexRow from "../FlexRow/FlexRow";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import tadariss from "../../images/tadariss.svg";
import { theme } from "../../theme/theme";
import { getSafe } from "../../helpers";

const Wrapper = styled.div`
  height: ${(props) => (props.open ? "100vh" : "0vh")};
  width: ${(props) => (props.open ? "100vw" : "0vw")};
  background: ${theme.green};
  position: fixed;
  transition: all 0.4s;
  border-bottom-right-radius: ${(props) => (props.open ? "0px" : "1000px")};
  top: 0;
  left: 0;

  z-index: ${(props) => (props.open ? 999999999 : -1)};
  > * {
    opacity: ${(props) => (props.open ? "1" : "0")};
  }
  .flexx {
    display: -webkit-flex;
    flex-direction: row;
    height: 100vh;
    text-decoration: none !important;
    align-items: flex-start !important;
    justify-content: flex-start;
    padding-top: 10%;
    &:hover {
      text-decoration: none !important;
    }
  }
  .row {
    display: -webkit-flex;
    flex-direction: row;
    gap: 20px;
    position: relative;
    left: 50px;
    justify-content: flex-start;
    align-items: flex-start;
    &:hover {
      text-decoration: none !important;
    }
    .col {
      display: -webkit-flex;
      flex-direction: column;
      gap: 32px;
      justify-content: center;
      &:hover {
        text-decoration: none !important;
      }
    }
    &__title {
      font-family: Sofia;
      font-style: normal;
      font-weight: normal;
      height: fit-content;
      transform: rotate(-90deg);
      margin-top: 7px !important;
      margin-top: 2.8125rem;
      font-size: 1.25rem;
      color: #ffac46 !important;
    }
    h3 {
      font-family: Ivy;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 120%;
      letter-spacing: 0.06em;
      color: #ffffff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .rightside {
    position: relative;
    object-fit: cover;
    width: 40%;
    height: 100vh;
    .close {
      position: absolute;
      right: 51px;
      top: 51px;
      width: 106px;
      height: 106px;
      background: ${theme.green};
      cursor: pointer;
      border-radius: 1000px;
      z-index: 9999;
      [class^="line"] {
        width: 19px;
        height: 2px;
        background: ${theme.white};
        position: absolute;
        left: 50%;
        top: 50%;
      }
      .line1 {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      .line2 {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    img {
      position: absolute;
      width: 100%;
      object-fit: cover;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 60;
    }
  }
`;

function Menu({ open, setOpen, local }) {
  const scrollTo = (id) => {
    const hash = document.getElementById(id);

    hash.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToRegion = () => {
    if (location.pathname !== `/${local}`) {
      history.push(`/${local}`);
      setOpen(false);
      setTimeout(function () {
        scrollTo("region");
      }, 5000);
    } else {
      setOpen(false);
      scrollTo("region");
    }
  };
  const scrollToInfo = () => {
    if (location.pathname !== `/${local}`) {
      history.push(`/${local}`);
      setOpen(false);
      setTimeout(function () {
        scrollTo("info");
      }, 5000);
    } else {
      setOpen(false);
      scrollTo("info");
    }
  };
  const history = useHistory();
  const location = useLocation();
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("../", "")] = r(item);
    });
    return images;
  }

  const images = importAll(
    require.context("../../images/Photos Menu", false, /\.(png|jpe?g|svg)$/)
  );
  const keys = Object.keys(images);
  let { t } = useTranslation();
  return (
    <Wrapper open={open}>
      <FlexRow>
        <div className="flexx">
          <img src={tadariss} alt="Tadariss" />
          <div className="row">
            <h2 className="row__title">Plan</h2>
            <div className="col">
              <h3
                onClick={() => {
                  history.push(`/${local}/plan`);
                  setOpen(false);
                }}
              >
                {t("tripplanner")}
              </h3>
              <h3
                onClick={() => {
                  history.push(`/${local}/lookfor/activites`);
                  setOpen(false);
                }}
              >
                {t("activities")}
              </h3>
              <h3
                onClick={() => {
                  history.push(`/${local}/lookfor/hebergement`);
                  setOpen(false);
                }}
              >
                {t("hotels")}
              </h3>
              <h3
                onClick={() => {
                  history.push(`/${local}/lookfor/restaurants`);
                  setOpen(false);
                }}
              >
                {t("restaurant")}
              </h3>
              <h3
                onClick={() => {
                  history.push(`/${local}/lookfor/agence-de-voyages`);
                  setOpen(false);
                }}
              >
                {t("travelAgencies")}
              </h3>
            </div>
          </div>
          <div className="row">
            <h2 className="row__title">Info</h2>
            <div className="col">
              {location.pathname !== `/${local}` ? (
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/${local}?#region`}
                >
                  <h3 onClick={() => setOpen(false)}>{t("theRegion")}</h3>
                </Link>
              ) : (
                <h3 onClick={() => scrollToRegion()}>{t("theRegion")}</h3>
              )}
              <h3
                onClick={() => {
                  history.push(`/${local}/about`);
                  setOpen(false);
                }}
              >
                {t("whoarewe")}
              </h3>
              {
                false && (
                  location.pathname !== `/${local}` ? (
                    <Link style={{ textDecoration: "none" }} to={`/${local}?#info`}>
                      <h3 onClick={() => setOpen(false)}>{t("praticalInfos")}</h3>
                    </Link>
                  ) : (
                    <h3 onClick={() => scrollToInfo()}>{t("praticalInfos")}</h3>
                  )
                )
              }
           
              <h3
                onClick={() => {
                  setOpen(false);
                  history.push(`/${local}/mapsguides`);
                }}
              >
                {t("guideMap")}
              </h3>
            </div>
          </div>
        </div>
        <div className="rightside">
          <div className="close" onClick={() => setOpen(false)}>
            <div className="line1"></div>
            <div className="line2"></div>
          </div>
          <img
            src={getSafe(
              () => images[keys[Math.floor(Math.random() * 8)]].default
            )}
            alt="img"
          />
        </div>
      </FlexRow>
    </Wrapper>
  );
}

export default Menu;
