import React from "react";
import styled from "styled-components";
import H1 from "../H1/H1";
import H4 from "../H4/H4";
import { useTranslation } from "react-i18next";
import facebook from "../../icons/facebook-outline.svg";
import policy from "../../files/policy.pdf";
import isMobile from "ismobilejs";
import instagram from "../../icons/instagram-outline.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import appstore from "../../images/appstore.svg";
import youtube from "../../icons/youtube-outline.svg";
import googleplay from "../../images/googleplay.svg";
import zaghrafa from "../../images/zaghrafa.svg";
import logo from "../../images/logowhite.svg";
import logo1 from "../../images/logom.png";
import logo2 from "../../images/logo-rfm.png";
const Wrapper = styled.div`
  background: #111111;
  position: relative;
  .lang {
    margin-top: 40px;
  }
  .fmk {
    width: 50%;
  }
  a {
    text-decoration: none;
    color: white;
  }
  .grid {
    display: grid;
    grid-template-columns: 0.3fr 0.3fr 0.3fr 0.2fr;
    grid-column-gap: 5rem;
    padding: 8.56rem 5rem;
    padding-right: 12rem;
    padding-left: 12rem;

    @media (max-width: 768px) {
      text-align: center;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 5rem;
      padding: 8.56rem 2rem;
      > * > * {
        font-size: 16px !important;
      }

      hr {
        color: white !important;
        width: 40% !important;
        position: relative;
        left: 50%;

        transform: translateX(-50%);
      }
      .fmk {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 45% !important;
        > * {
          font-size: 16px !important;
        }
      }
      .appimg {
        width: 195px !important;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .center {
      align-items: flex-start;
      @media (max-width: 768px) {
        flex-direction: row !important;
        position: relative !important;
        display: -webkit-flex !important;
        left: 50% !important;
        width: fit-content;
        transform: translateX(-50%);
        img {
          width: 25px !important;
          height: 25px !important;
        }
      }
    }
    .box {
      display: -webkit-flex;
      flex-direction: column;

      > * {
        cursor: pointer;
      }

      gap: 20px;
      img {
        width: 100%;
      }
      .social {
        display: -webkit-flex;
        flex-direction: row;
        gap: 10px;
        text-decoration: none;
      }
    }

    > * > * {
      color: white !important;
      > * {
        color: white !important;
        text-transform: capitalize;
      }
      span {
        opacity: 0.18 !important;
      }
    }
  }
  .u-center {
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      > *:first-child {
        font-size: 48px !important;
      }
      > *:not(:first-child, hr) {
        font-size: 16px !important;
        margin-bottom: 20px;
        width: 70% !important;
        text-align: center !important;
      }
      hr {
        color: white !important;
        width: 40% !important;
        position: relative;
        margin-top: -10px !important;
        margin-bottom: 10px !important;
      }
    }
    gap: 20px;
    padding-bottom: 10rem;
    .logos {
      display: -webkit-flex;
      flex-direction: row;
      gap: 20px;
      img {
        object-fit: contain;
        width: 100px;
        height: 80px;
      }
      .img {
        width: 150px;
      }
    }
    hr {
      color: white !important;
      width: 80%;
    }

    > * {
      color: white !important;
    }
  }
  .zaghrafa {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

function Footer() {
  let history = useHistory();
  const { t } = useTranslation();
  const { local } = useSelector((state) => state.Local);
  return (
    <Wrapper>
      <div className="grid">
        <div className="box left">
          <H4 handleClick={() => history.push(`/${local}/about`)}>
            {t("whoarewe")}
          </H4>
          <H4 handleClick={() => history.push(`/${local}/mapsguides`)}>
            {t("documentation")}
          </H4>
      
          <H4>
            <a target="_blank" rel="noopener noreferrer" download href={policy}>
              {t("privacyPolicy")}
            </a>
          </H4>
          <H4 handleClick={() => history.push(`/${local}/contact`)}>
            {t("contact")}
          </H4>
        </div>
        {isMobile().phone && <hr />}
        <div className="box">
          <H4 style={{textTransform:'lowercase'}}>contact@visitfesmeknes.ma</H4>
          <H4>+212 5 35 65 01 88</H4>
          <H4>+212 5 35 94 24 92</H4>
          <div className="fmk">
            <H4>Avenue Des Alaouites – Fès 30.000</H4>
          </div>
        </div>
        <div className="box center">
          <a
            className="social"
            href="https://www.facebook.com/CRTFES2021/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" />
            {!isMobile().phone && <H4>facebook</H4>}
          </a>
          <a
            className="social"
            href="https://www.instagram.com/crt.fes"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" />
            {!isMobile().phone && <H4>instagram</H4>}
          </a>
          <a
            className="social"
            href="https://www.youtube.com/channel/UCzs0i-RRygtw_A6iLXG-u_Q/videos"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="youtube" />
            {!isMobile().phone && <H4>Youtube</H4>}
          </a>
        </div>

        <div className="box">
          <a
            href="https://apps.apple.com/ma/app/fes-meknes/id1601692743?l=fr"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <img className="appimg" src={appstore} alt="appstore" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=ma.ctd.visitfesmeknes"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <img className="appimg" src={googleplay} alt="googleplay" />
          </a>
        </div>
      </div>
      <div className="u-center">
        <H1 alotbig>Get in touch</H1>
        <hr />
        <div className="logos">
          <img className="img" src={logo2} alt="logo3" />
          <img src={logo} alt="logo2" />
          <img className="img" src={logo1} alt="logo1" />
        </div>
        <H4 signature>
          © 2021 {t("copyrightFooter")}. <br />
          <a href="https://ctd.ma">Casbah Tourism Development</a>
        </H4>
      </div>
      <div className="zaghrafa">
        <img width="100%" src={zaghrafa} alt="zaghrafa" />
      </div>
    </Wrapper>
  );
}

export default Footer;
