import imagenondisponible from "./images/imagenondisponible.png";
import blurryImage from "./images/blurry.jpg";
export const PERSISTANCE_KEY = "FM-050";
export const TOUR_BUILDER_DEFAULT_NBR_DAYS = 4;
export const API_ENDPOINT =
  "https://staging-api-visitfesmeknes-com.cloud.marrakech.io";
export const DEV_API_ENDPOINT = "http://127.0.0.1:4040";
export const CDN_ENDPOINT = "https://d3lq8p6p6r1qxf.cloudfront.net";
export const USER_IP_ENDPOINT = "https://json.geoiplookup.io";
export const API_NEWSLETTER = API_ENDPOINT + "/newsletter";
export const API_CONTACT = API_ENDPOINT + "/contact";

/**
 * Vote Endpoind Schema
 *
 * id: {
      type: String,
    },
    type: {
      type: Number, //circuit=03, place = 01, tour=02
    },
    like: {
      type: Boolean
    }
 */
export const API_VOTES = API_ENDPOINT + "/vote";
export const API_PLACES = API_ENDPOINT + "/cache/places";
export const API_MICE = API_ENDPOINT + "/mice";
export const API_QUOTES = API_ENDPOINT + "/quote";
export const API_ARTICLES = API_ENDPOINT + "/articles";
export const API_DEALS = API_ENDPOINT + "/cache/places?isDeal=true";
export const API_TAGS = API_ENDPOINT + "/tags";
export const API_CITIES = API_ENDPOINT + "/city";
export const API_CIRCUITS = API_ENDPOINT + "/circuits";
export const API_TOURS = API_ENDPOINT + "/tours";
export const API_STATS = API_ENDPOINT + "/cache/bi";
export const API_STORIES = API_ENDPOINT + "/stories";
export const API_INSTAWALL = API_ENDPOINT + "/instawall";
export const API_STATS_LIVE = API_ENDPOINT + "/bi";
export const API_AUTH = API_ENDPOINT + "/auth/login";
export const API_AUTH_IS_LOGGED_IN = API_ENDPOINT + "/auth/isLoggedIn"; //Not implemented yet
export const API_UPLOAD = API_ENDPOINT + "/media";
export const CLOUDINARY =
  "https://cdn.visitbenimellal.ma/fetch/c_fit,g_center,h_600/";
export const MAPBOX_ACCESS_TOKEN = {
  accessToken:
    "pk.eyJ1IjoiaWJheW91c3NlZiIsImEiOiJja2w5aWU2NjQwbnc2MnJuMGxvaHAyYmUwIn0._2BWm8cwRivc5Vpf899Lhg",
};
export const MAPBOX_STYLE_URL =
  "mapbox://styles/comepic/ckqr0s5iw1wkb18qyprvnrmpz";
export const NO_IMAGE = imagenondisponible;
export const BLURRY_IMAGE = blurryImage;

//exclude from tripPlanner
export const TRIP_PLANNER_ARRAY = [
  "activite",
  "restaurant",
  "evenement",
  "transport",
  "hotel",
  "agence-de-voyages",
  "cafe",
  "packages",
  "visite-guidee",
  "hotel-connect",
  "circuit-decouvertes",
];

//exact main menu Tags
export const EXACT_MAIN_MENU_TAGS = [
  "lacs-et-barrages",
  "culture-et-vestiges",
  "espaces-naturels",
  "activites",
];

//exact deal tags
export const EXACT_DEAL_TAGS = [
  "hebergement-deal",
  "activite-deal",
  "sejour-deal",
  "restaurant-deal",
];

//exact trip planner tags
export const EXACT_TRIP_PLANNER_ARRAY = [
  "culture",
  "nature",
  "balneaire",
  "adrenaline",
  "activites-aquatiques",
  "activites-sportives",
];
export const cities1 = [
  {
    id: 486,
    name: "Sefrou",
    coordinates: [-4.83, 33.82],
  },
  {
    id: 158,
    name: "Ifrane",
    coordinates: [-5.12, 33.51],
  },
  {
    id: 2468,
    name: "Taounate",
    coordinates: [-4.64, 34.54],
  },
  {
    id: 3879,
    name: "Fes",
    coordinates: [-5.01, 34.02],
  },
  {
    id: 4498,
    name: "Boulmane",
    coordinates: [-4.72, 33.36],
  },
];
export const cities2 = [
  {
    id: 5849,
    name: "Meknès",
    coordinates: [-5.54, 33.87],
  },
  {
    id: 6549,
    name: "EL Hajeb",
    coordinates: [-5.37, 33.68],
  },
  { id: 4642, name: "Taza", coordinates: [-3.99, 34.22] },
  {
    id: 79797,
    name: "Moulay Yacoub",
    coordinates: [-5.17, 34.08],
  },
];
export const BROCAT_ARRAY = [
  "ligne de bus",
  "plan de development",
  "carte touristique",
  "catalogue",
];

export const BROCHURES_ARRAY = ["papiers", "électronique"];
export const EAT_ARRAY = ["restaurants"];

//todos arrays
export const TODO_ARRAY = ["activites"];

// See Tags
export const SEE_ARRAY = ["culture-et-vestiges", "espaces-naturels"];

export const ALL_ARRAY = [
  "culture-et-vestiges",
  "espaces-naturels",
  "activites-aquatiques",
  "activites",
  "restaurants",
];

export const FILTERS_ARRAY = [
  "culture-et-vestiges",
  "espaces-naturels",
  "activites",
  "lacs-et-barrages",
  "evenements",
];

//include to Explore menu
export const EXPLORE_ARRAY = [
  "espaces-naturels",
  "culture-et-vestiges",
  "lacs-et-barrages",
];

export const JSON_HEADERS = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
};

export const filter =
  "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 23.78%), radial-gradient(53.73% 53.73% at 42.09% 46.27%, rgba(0, 0, 0, 0.1) 60.43%, rgba(0, 0, 0, 0.2) 99.48%)";

export const SUPPORTED_LOCALS = {
  fr: "Français",
  es: "Español",
  en: "English",
  de: "Allemand",
};

export const DEFAULT_MAP_CENTER = [-5.01, 34.02];
export const DEFAULT_MAP_REGION = { longitude: -5.01, latitude: 34.02 };

export const SECTIONS_DEF = {
  story: "story",
  topExperience: "topExperience",
  regionMap: "regionMap",
  circuit: "circuit",
  regionMapMultiSelect: "regionMapMultiSelect",
  hoverMap: "hoverMap",
  storyEvent: "storyEvent",
  instaWall: "instaWall",
};
