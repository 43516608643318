import { API_CITIES, JSON_HEADERS } from '../constants'
import { message } from '../helpers';

export const SET_CITIES = 'SET_CITIES'

// setAuthToken
function setCitites(cities) {
    return {
        type: SET_CITIES,
        payload: cities
    }
}

// get cities from the server
export function fetchSetCities() {
    return function (dispatch) {
        return fetchApiGetCities()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setCitites(response.data))
                    } else {
                        message.error('Something bad happens. Code: TE01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: TE02'))
    }
    
}

function fetchApiGetCities() {
    return fetch(API_CITIES ,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}