export const theme = {
  black: "#000000",
  blackish: "#161616",
  lightblack: "#0E0D12",
  geryish: "#111111",
  white: "#FFFFFF",
  grey: "#F7F7F7",
  darkblue: "#0A1A2D",
  lightblue: "#008382",
  lightgreen: "#307361",
  green: "#1C473B",
  yellow: "#FFA300",
  lightyellow: "#FFAC46",
  yellowish: "#FAE47C",
  orange: "#FF3300",
  red: "#ED5F4B",
  darkred: "#E13100",
  blurryright:
    "linear-gradient(-90deg, #FFFFFF 33.73%, rgba(249, 249, 249, 0.890625) 56.49%, rgba(255, 255, 255, 0) 100%)",
  blurryleft:
    "linear-gradient(90deg, #FFFFFF 33.73%, rgba(249, 249, 249, 0.890625) 56.49%, rgba(255, 255, 255, 0) 100%)",
};
