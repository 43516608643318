import { SET_FILTERS } from "../actions/filters";
import { DEFAULT_MAP_CENTER } from "../constants";

const initState = {
  filters: {
    tags: [],
    cities: [],
    seedoeat: [],
    triptags: [], //[]
    keyword: null,
    startingPoint: DEFAULT_MAP_CENTER,
    budget: null,
    dates: {},
    stade: "planning",
  },
};

export default function placesReducer(state = initState, action) {
  switch (action.type) {
    case SET_FILTERS:
      return {
        filters: { ...state.filters, ...action.payload },
      };
    default:
      return state;
  }
}
