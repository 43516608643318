import { USER_IP_ENDPOINT } from '../constants'

export const SET_USER = 'SET_USER'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_USER_SUBSCRIBED = 'SET_USER_SUBSCRIBED'
export const SET_USER_LIVE_LOCATION = 'SET_USER_LIVE_LOCATION'

// setAuthToken
function setUserDetails(userIp) {
    return {
        type: SET_USER,
        payload: userIp
    }
}

// setAuthToken
export function setUserLiveLocation(userLocation) {
    return {
        type: SET_USER_LIVE_LOCATION,
        payload: userLocation
    }
}

// get articles from the server
export function fetchsetUserDetails() {
    return function (dispatch) {
        return fetchApiGetUserInfos()
                .then(function(res){ return res.json(); })
            .then(function (response) {
                    if (response && response.ip) {
                        dispatch(setUserDetails(response))
                    } else {
                        console.error('Something bad happens. Code: USR01')
                    }
                })
                .catch(e=>console.error('Something bad happens. Code: USR02'))
    }
    
}

function fetchApiGetUserInfos() {
    return fetch(USER_IP_ENDPOINT ,{
        method: "GET",
      
    })
}


export function setSubscribedToNewsletter() {
    return {
        type: SET_USER_SUBSCRIBED
    }
}