import { SET_USER, SET_USER_ID, SET_USER_SUBSCRIBED, SET_USER_LIVE_LOCATION } from '../actions/user'

const initState = {
  userId: null,
  user: {},
  location:{},
  newsletter: false,
  subscribedToNewsletter: false
}
  
export default function userReducer (state = initState, action) {
  switch (action.type) {
    case SET_USER:
      return {
            ...state,
            user:action.payload
      }
      case SET_USER_LIVE_LOCATION:
        return {
              ...state,
              location:action.payload
        }
        case SET_USER_ID:
          return {
                ...state,
                userId: action.payload
        }
      case SET_USER_SUBSCRIBED:
        return {
          ...state,
          newsletter: true,
          subscribedToNewsletter: true
        }
      default:
        return state
    }
  }
  
