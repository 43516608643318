/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { theme } from "../../theme/theme";
import home from "../../icons/home.svg";
import homeact from "../../icons/homeact.svg";
import plan from "../../icons/Plan.svg";
import planact from "../../icons/Planact.svg";
import H4 from "../../components/H4/H4";
import Menu from "../../components/Menu/Menu";
import search from "../../icons/search-outline.svg";
// eslint-disable-next-line no-unused-vars
import searchact from "../../icons/searchact.svg";
import fav from "../../icons/favorites.svg";
import favact from "../../icons/favoritesact.svg";
import map from "../../images/menu.svg";
import menu from "../../images/menuact.svg";
// eslint-disable-next-line no-unused-vars
const Wrapper = styled.div`
  width: 100%;
  height: 68px;
  background-color: ${theme.white};
  position: fixed;
  display: -webkit-flex;
  left: 0;
  bottom: 0;
  z-index: 99999999 !important;

  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 24px 47px !important;
  .search {
    ${(props) =>
      props.searchpage
        ? `>h4 {
      display: none;
    }
    img {
      margin-top: -20px;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      z-index: 9999999999;
      left: 0;
      width: 20px;
      height: 50px;
      background: #ed5f4b;
      clip-path: ellipse(50% 25% at 50% 110%);
    }`
        : null}
  }
  gap: 40px;
  .item {
    display: -webkit-flex;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.4s;
    position: relative;
    height: 70px;
    gap: 6px;
    align-items: center;
    justify-content: center;
    &::after {
      transition: all 0.4s;
    }
    img {
      width: 20px !important;
      transition: all 0.4s;
    }
  }
  .home {
    ${(props) =>
      props.homepage
        ? `>h4 {
      display: none;
    }
    img {
      margin-top: -20px;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      z-index: 9999999999;
      left: 0;
      width: 20px;
      height: 50px;
      background: #ed5f4b;
      clip-path: ellipse(50% 25% at 50% 110%);
    }`
        : null}
  }
  .plan {
    ${(props) =>
      props.planpage
        ? `>h4 {
      display: none;
    }
    img {
      margin-top: -20px;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      z-index: 9999999999;
      left: 0;
      width: 20px;
      height: 50px;
      background: #ed5f4b;
      clip-path: ellipse(50% 25% at 50% 110%);
    }`
        : null}
  }
  .fav {
    ${(props) =>
      props.favorite
        ? `>h4 {
      display: none;
    }
    img {
      margin-top: -20px;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      z-index: 9999999999;
      left: 0;
      width: 20px;
      height: 50px;
      background: #ed5f4b;
      clip-path: ellipse(50% 25% at 50% 110%);
    }`
        : null}
  }

  .menu {
    ${(props) =>
      props.open
        ? `>h4 {
      display: none;
    }
    img {
      margin-top: -20px;
      transform: rotate(90deg)
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      z-index: 9999999999;
      left: 0;
      width: 20px;
      height: 50px;
      background: #ed5f4b;
      clip-path: ellipse(50% 25% at 50% 110%);
    }`
        : null}
  }
`;

function MobileNav() {
  const history = useHistory();
  const location = useLocation();
  const { local } = useSelector((state) => state.Local);
  const [open, setOpen] = React.useState(false);

  const favorite = location.pathname === `/${local}/fav`;
  const homepage = location.pathname === `/${local}`;
  const planpage = location.pathname === `/${local}/plan`;
  const searchpage = location.pathname === `/${local}/search`;
  return (
    <Wrapper
      favorite={favorite}
      planpage={planpage}
      searchpage={searchpage}
      homepage={homepage}
      open={open}
    >
      <Menu open={open} setOpen={setOpen} local={local} />

      <div className="item home" onClick={() => history.push(`/${local}`)}>
        <img src={homepage ? homeact : home} alt="home" />
        <H4>Home</H4>
      </div>
      <div className="item plan" onClick={() => history.push(`/${local}/plan`)}>
        {" "}
        <img src={planpage ? planact : plan} alt="home" />
        <H4>Plan</H4>
      </div>
      <div
        className="item search"
        onClick={() => history.push(`/${local}/search`)}
      >
        <img src={searchpage ? searchact : search} alt="home" />
        <H4>Search</H4>
      </div>
      <div className="item fav" onClick={() => history.push(`/${local}/fav`)}>
        <img src={favorite ? favact : fav} alt="home" />
        <H4>Favorites</H4>
      </div>
      <div className="item menu" onClick={() => setOpen(!open)}>
        <img src={open ? menu : map} alt="home" />
        <H4>Menu</H4>
      </div>
    </Wrapper>
  );
}

export default MobileNav;
