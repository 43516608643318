import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: -webkit-flex;
  flex-direction: row;

  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
  align-items: ${(props) => (props.alignItems ? props.alignItems : null)};
  background-color: ${(props) =>
    props.nocolor ? "transparent" : props.tight ? "#111111" : null};
  justify-content: ${(props) =>
    props.tight ? "center" : props.start ? "flex-start" : "space-between"};
  margin: ${(props) =>
    props.nomargin
      ? "-85px 0px -130px 0px"
      : props.tight
      ? "-124px 0px "
      : null};
  margin-top: ${(props) => (props.contact ? "-11rem" : null)};
  gap: ${(props) => (props.gap ? props.gap : null)};

  .filter {
    width: 30%;

    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
    > div {
      overflow: auto;
      position: sticky !important;
      top: 80px;
      left: 0;
    }
  }
  .aboutimg {
    width: 55%;
    height: 63rem;
  }
  .img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

function FlexRow({
  children,
  tight,
  gap,
  nocolor,
  alignItems,
  nomargin,
  contact,
  start,
}) {
  return (
    <Wrapper
      tight={tight}
      contact={contact}
      start={start}
      gap={gap}
      nocolor={nocolor}
      alignItems={alignItems}
      nomargin={nomargin}
    >
      {children}
    </Wrapper>
  );
}

export default FlexRow;
