import { API_VOTES, JSON_HEADERS } from "../constants";

export const USER_LIKE = "USER_LIKE";
export const USER_DISLIKE = "USER_DISLIKE";

export const likeAnId = (payload) => {
  return {
    type: USER_LIKE,
    payload,
  };
};

export const dislikeAnId = (payload) => {
  return {
    type: USER_DISLIKE,
    payload,
  };
};

// like [Boolean] false means dislike, true like
// id [string]
export function postsetVote({id, like}) {
  fetch(API_VOTES, {
    method: "POST",
    headers: JSON_HEADERS,
    body: JSON.stringify({id, like}),
  })
    .then(function (response) {
      return response.json();
    })
    .catch(function (e) {
      console.error("__VOTE__SYNC__ERROR");
    });
}
