import { API_INSTAWALL, JSON_HEADERS } from '../constants'
import { message } from '../helpers';

export const SET_INSTAWALL = 'SET_INSTAWALL'

// setAuthToken
function setInstaWallImages(instawallImages) {
    return {
        type: SET_INSTAWALL,
        payload: instawallImages
    }
}

// get instawallImages from the server
export function fetchSetInstaWallImages() {
    return function (dispatch) {
        return fetchApiGetInstaWallImages()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setInstaWallImages(response.data))
                    } else {
                        message.error('Something bad happens. Code: INST01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: INST02'))
    }
    
}

function fetchApiGetInstaWallImages() {
    return fetch(API_INSTAWALL ,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}