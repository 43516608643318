import { API_TOURS, JSON_HEADERS } from '../constants'
import { message } from '../helpers';

export const SET_EXPERIENCES = 'SET_EXPERIENCES'

// setAuthToken
function setExperiences(experiences) {
    return {
        type: SET_EXPERIENCES,
        payload: experiences
    }
}

// get experiences from the server
export function fetchSetExperiences() {
    return function (dispatch) {
        return fetchApiGetExperiences()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setExperiences(response.data))
                    } else {
                        message.error('Something bad happens. Code: EE01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: EE02'))
    }
    
}

function fetchApiGetExperiences() {
    return fetch(API_TOURS ,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}