import { USER_LIKE, USER_DISLIKE } from "../actions/vote";

const initState = {
  liked: [],
  disliked: [],
};

export default function tagsReducer(state = initState, action) {
  const liked = state.liked;
  const disliked = state.disliked
  const isLiked = (place) => liked.indexOf(place) > -1;
  const isDisliked = (place) => disliked.indexOf(place) > -1;
  
  switch (action.type) {
    case USER_LIKE:
      const likedPlace = action.payload;
    

      if (isLiked(likedPlace)) {
        return {
          ...state
        }
      }

      if (isDisliked(likedPlace)) {
        const filtredDisliked = disliked.filter((f) => f !== likedPlace);
        return {
          ...state,
          liked: [...state.liked, action.payload],
          disliked: [...filtredDisliked],
        };
      }

      return {
        ...state,
        liked: [...state.liked, action.payload],
      };

    case USER_DISLIKE:
      const dislikedPlace = action.payload;

      if (isDisliked(dislikedPlace)) {
        return {
          ...state
        }
      }

      if (isLiked(dislikedPlace)) {
        const filtredLiked = liked.filter((f) => f !== dislikedPlace);
        return {
          ...state,
          disliked: [...state.disliked, action.payload],
          liked: [...filtredLiked],
        };
      }
      
      return {
        ...state,
        disliked: [...state.disliked, action.payload],
      };

    default:
      return state;
  }
}
