import { SET_INSTAWALL } from '../actions/instawall'

const initState = {
    instawall: []
}
  
export default function instawallReducer (state = initState, action) {
    switch (action.type) {
      case SET_INSTAWALL:
            return {
              instawall: action.payload
        }
      default:
        return state
    }
  }
  
