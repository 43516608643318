import { API_ARTICLES, JSON_HEADERS } from '../constants'
import { message } from '../helpers';

export const SET_ARTICLES = 'SET_ARTICLES'

// setAuthToken
function setArticles(articles) {
    return {
        type: SET_ARTICLES,
        payload: articles
    }
}

// get articles from the server
export function fetchSetArticles() {
    return function (dispatch) {
        return fetchApiGetArticles()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        const sortedArticles = response.data
                            .sort((a, b) => {
                                if (a.slug < b.slug) {
                                    return -1;
                                }
                                if (a.slug > b.slug) {
                                    return 1;
                                }
                                return 0;
                            })
                        dispatch(setArticles(sortedArticles))
                    } else {
                        message.error('Something bad happens. Code: AE01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: AE02'))
    }
    
}

function fetchApiGetArticles() {
    return fetch(API_ARTICLES ,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}