import React from "react";
import styled from "styled-components";
import { theme } from "../../theme/theme";
import P from "../P/P";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import facebook from "../../icons/facebook-outline.svg";
import instagram from "../../icons/instagram-outline.svg";
import youtube from "../../icons/youtube-outline.svg";
import globe from "../../icons/global.svg";
import profile from "../../icons/profile-circle.svg";
const Wrapper = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${theme.green};
  padding: 0.8rem 7.625rem;
  .socials {
    display: -webkit-flex;
    flex-direction: row;
    gap: 14.5px;
    .element {
      display: -webkit-flex;
      flex-direction: row;
      text-decoration: none;
      gap: 12px;
      > * {
        color: ${theme.white}!important;
        font-weight: 100 !important;
      }
      &:not(:last-child) {
        border-right: 1px solid ${theme.white};
        padding-right: 14.5px;
      }
    }
  }
  .utils {
    display: -webkit-flex;
    flex-direction: row;
    gap: 29px;
    .element {
      display: -webkit-flex;
      flex-direction: row;
      gap: 12px;
      > * {
        color: ${theme.white}!important;
        font-weight: 100 !important;
        cursor: pointer !important;

        > * {
          color: ${theme.white}!important;
          font-weight: 100 !important;
          cursor: pointer;
        }
      }
    }
  }
`;
function UpperNav() {
  return (
    <Wrapper>
      <div className="socials">
        <a
          className="element"
          href="https://web.facebook.com/CRTFES2021"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="facebook" />
          <P>Facebook</P>
        </a>

        <a
          className="element"
          href="https://www.instagram.com/crt.fes"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagram" />
          <P>Instagram</P>
        </a>
        <a
          className="element"
          href="https://www.youtube.com/channel/UCzs0i-RRygtw_A6iLXG-u_Q/videos"
          target="_blank"
          rel="noreferrer"
        >
          <img src={youtube} alt="youtube" style={{width:'20px', height:'20px', color: 'white'}}/>
          <P>Youtube</P>
        </a>
      </div>
      <div className="utils">
        <div className="element">
          <img src={globe} alt="language" />
          <LanguageSwitcher />
        </div>
        <div className="element">
          <img src={profile} alt="connexion" />
          <P>Connexion</P>
        </div>
      </div>
    </Wrapper>
  );
}

export default UpperNav;
