import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import P from "../P/P";
import styled from "styled-components";
import { theme } from "../../theme/theme";
import { setLocal } from "../../actions/local";
import history from "../../history";
import { SUPPORTED_LOCALS } from "../../constants";
export default function LanguageSwitcher() {
  const { local } = useSelector((state) => state.Local);
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const Dropdown = styled.div`
    position: relative;
    .input {
      position: absolute;
      appearance: none;
      opacity: 0;
      z-index: 999999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &:focus ~ .langs {
        height: 14.28rem;
        z-index: 999999;
        > * {
          opacity: 1;
        }
      }
    }
    button {
      > * {
        color: ${theme.white};
        font-weight: 100;
      }
      background: transparent;
      border: none;
    }

    .langs {
      height: 0px;
      position: absolute;
      transition: all 0.4s;
      z-index: -1;
      background: ${theme.lightgreen};
      > * {
        color: ${theme.white};
        font-weight: 100;
        padding: 1rem 1rem;
        cursor: pointer;
        opacity: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  `;
  useEffect(() => {
    const pathName = window.location.pathname;
    const pathLocal = pathName.split("/")[1];
    if (SUPPORTED_LOCALS[pathLocal]) {
      dispatch(setLocal(pathLocal));
    }
  }, [dispatch]);

  return (
    <Dropdown>
      <input className="input" />
      <button id="dropdown-language-switcher">
        <P>{SUPPORTED_LOCALS[local]}</P>
      </button>

      <div className="langs">
        {Object.keys(SUPPORTED_LOCALS).map((l, k) => (
          <P
            key={l + k}
            onClick={() => {
              setActive(!active);
              const currentLocation = window.location.pathname;
              const newLocation = currentLocation.replace(
                "/" + local + "/",
                "/" + l + "/"
              );
              history.replace(newLocation);
              dispatch(setLocal(l));
            }}
          >
            {SUPPORTED_LOCALS[l]}
          </P>
        ))}
      </div>
    </Dropdown>
  );
}
