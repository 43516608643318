import { API_PLACES, JSON_HEADERS } from '../constants'
import { message } from '../helpers';

export const SET_PLACES = 'SET_PLACES'

function setPlaces(places) {
    return {
        type: SET_PLACES,
        payload: places
    }
}

// get places from the server
export function fetchSetPlaces() {
    return function (dispatch) {
        return fetchApiGetPlaces()
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setPlaces(response.data))
                    } else {
                        message.error('Something bad happens. Code: PE01')
                        if (response.message) {
                            message.error(response.message)
                        }
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: PE02'))
    }
    
}

function fetchApiGetPlaces() {
    return fetch(API_PLACES,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}